
h1 {
  font-size: 36px;
  font-weight: bold;
  color: #333333;
  margin: 20px;
}
button {
  border: none;
  background-color: #F7931A;
  color: #FFF;
  font-size: 16px;
  padding: 7px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button:hover {
  background-color: #E67E22;
}
table {
  width: 80%;
  margin: 20px auto;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #FAF3DD;
}

th,
td {
  padding: 15px;
  text-align: left;
}

thead {
  background-color: #f2f2f2;
}

th {
  font-weight: bold;
  color: #333333;
}

tbody tr:nth-child(even) {
  background-color:#FAF3DD;
}

tbody tr:hover {
  background-color: #FAF3DD;
}

td:nth-child(2) {
  text-transform: capitalize;
}

td:nth-child(3) {
  color: #008000;
}

td:nth-child(4) {
  font-weight: bold;
}

/*------------*/
body {
  background-color: #e9ebf0;
  font-family: Helvetica, Arial;
}
.header {
  background-color: #0093d5;
  padding: 10px;
}
.header h1 {
  color: #fff;
}
.wrapper {
  margin-top: 50px;
  background-color: #fff;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  width: 50%;
}
.wrapper p {
  font-style: italic;
  color: #666;
}
.wrapper label {
  display: block;
}
.wrapper input {
  width: 600px;
  padding: 3px 6px;
}
.amount-input {
  margin-bottom: 10px;
}
h2 {
  margin-top: 0;
}
p{
  margin-top: 5px;
  margin-bottom: 5px;
}
.poster {
  display:inline-block;
}
.postering{
  display:block;
}
.news-block {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
}

.newspaper-image {
  flex: 0 0 auto;
  margin-right: 20px;
}

.newspaper-image img {
  max-width: 100%;
}

.news-content {
  flex: 1 1 auto;
}

.news-title {
  margin-top: 0;
}

.news-description {
  margin-bottom: 10px;
}

.news-meta {
  display: flex;
  flex-direction: column;
}

.news-meta .news-category {
  font-weight: bold;
  margin-bottom: 5px;
}

.news-meta .news-date {
  font-style: italic;
}
.news-item {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.news-item:last-of-type {
  border-bottom: none;
}
.select-wrapper {
  position: relative;
  background-color: #0093d5;
}
.select-box {
  /* existing styles */
  position: relative;
  background-color: #0093d5;
}

.select-box:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 8px solid #666;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  pointer-events: none;
}

.select-box:focus {
  outline: none;
}

.select-box option {
  font-size: 16px;
  background-color: #0093d5;
  color: #666;
}

.select-box option:hover {
  background-color: #f2f2f2;
}

.select-box option:checked {
  background-color: #0093d5;
  color: #333;
}
/* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.button {
  margin: 10px 2px;
  padding: 5px 20px;
  border-radius: 8px;
  background-color: #0093d5;
  border: none;
  color: #fff;
  text-align: center;
  display: inline-block;
  font-size: small;
}
.button:hover {
  cursor: pointer;
  opacity: 0.75;
}
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 }
 
.wrapper hr {
  margin: 40px auto;
}
@media only screen and (min-width: 1400px) {
  .wrapper {
    width: 50%;
  }
  .wrapper input {
    width: 600px;
  }
}
@media only screen and (max-width: 1399px) and (min-width: 750px) {
  .wrapper {
    width: 75%;
  }
  .wrapper input {
    width: 400px;
  }
}
.wrapper input{
    width:75%;
    max-width:600px;
} /* extra code to make input fields accustomed to resizing*/
